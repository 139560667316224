import React, { useContext, useState, useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Container, Grid, Link, Paper, Skeleton, Slide, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import ConfigContext from "../../common/context/ConfigContext";
import { Link as RouterLink } from "react-router-dom";
import Review from "../../common/component/Review";
import Masonry from "@mui/lab/Masonry";
import Flavors from "../../common/component/flavor/Flavors";
import { STORAGE_BUCKET } from "../../common/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import Preview from "../../common/component/Preview";
import Stagger from "../../common/component/animate/Stagger";
import ItemsShowcase from "../../product/component/ItemsShowcase";

export default function Home(props) {
  const siteConfig = useContext(ConfigContext);

  let location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [section, setSection] = useState("");

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/home`).then((res) => {
      setSection(res.data.section);
    });

    //getRemoteConfigValue("frenchycakedesigns").then((rslt) => { console.log(rslt) });
  }, []);

  useEffect(() => {
    const hash = location.hash.replace("#", "");

    // add delay to let page load
    setTimeout(() => {
      const el = document.getElementById(hash);
      if (el) {
        el.scrollIntoView();
      }
    }, 1000);
  }, [section, location.hash]);

  if (section) {
    const img = (isMobile && section.hero.imgMobile) || section.hero.img;
    let hero = "";
    let bgImg = "";
    if (img.includes("res.cloudinary.com")) {
      bgImg = `url("${img}")`;
    } else {
      bgImg = `url("${STORAGE_BUCKET}${img}")`;
    }
    let heroOverlayColor = "#000";

    if (section.hero.overlay === "light") {
      // dark the background
      hero = `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${bgImg}`;
      heroOverlayColor = "#FFF";
    }

    let CTAS = [
      {
        cta: section.hero.primary?.cta || "Get Quote",
        link: section.hero.primary?.link || "/get-quote",
        type: "primary",
        title: section.hero.primary?.title || "Custom Cakes & Treats",
        subtitle: section.hero.primary?.subtitle || "Made to order custom cakes, cupcakes & more!"
      }
    ];

    if (siteConfig.header?.menu) {
      // custom menu, check if it has shop
      let shopMenu = siteConfig.header.menu.filter((o) => o.link === "/shop");
      if (shopMenu.length > 0) {
        CTAS.push({
          cta: section.hero.secondary?.cta || "Order Now",
          link: section.hero.secondary?.link || "/shop",
          type: "secondary",
          title: section.hero.secondary?.title || "Everyday Cakes & Desserts",
          subtitle: section.hero.secondary?.subtitle || "Our classic cakes & desserts available all day"
        });
      }
    }

    let heroContainer = (
      <Container maxWidth="md" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <Box sx={{ color: heroOverlayColor }}>
          <Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
            <Box>
              <Typography variant="h1" style={{ color: heroOverlayColor, fontSize: "calc(100% + 3vw)", fontWeight: "700" }}>
                {siteConfig.biz}
              </Typography>
              <Typography variant="subtitle1" style={{ color: heroOverlayColor, fontSize: "calc(80% + 1vw)" }}>
                {section.hero.subtitle}
              </Typography>
            </Box>
          </Slide>
          <Box my={6} sx={{ display: "flex", gap: "15px", justifyContent: "center", flexWrap: "wrap" }}>
            {CTAS.map((o) => (
              <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={1000}>
                <Button
                  component={RouterLink}
                  to={o.link}
                  size="large"
                  variant="contained"
                  color={o.type}
                  style={{ display: "block", textAlign: "center", textTransform: "none", width: "300px", padding: "1rem" }}
                >
                  <Typography variant="h4" gutterBottom>
                    {o.title}
                  </Typography>
                  <Typography variant="subtitle1">{o.cta.toUpperCase()}</Typography>
                </Button>
              </Slide>
            ))}
          </Box>
        </Box>
      </Container>
    );

    return (
      <Box>
        {section.banner && (
          <Box sx={{ backgroundColor: "#333", color: "#FFF", padding: "10px", textAlign: "center" }}>
            <Carousel
              autoPlay
              infiniteLoop
              interval={3000}
              transitionTime={1500}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
            >
              {section.banner.map((o, idx) => {
                return (
                  <Link underline="none" component={RouterLink} to={o.link} sx={{ color: "inherit" }}>
                    <Box>
                      <Typography variant="body2">{o.text}</Typography>
                    </Box>
                  </Link>
                );
              })}
            </Carousel>
          </Box>
        )}
        <Box textAlign="center" sx={{ height: "90vh", backgroundImage: hero, backgroundSize: "cover" }}>
          {heroContainer}
        </Box>
        <ItemsShowcase />
        {/*section.categories && (
          <Box py={15} sx={{ background: `linear-gradient(#CCC, #fff)` }}>
            <Container maxWidth="lg">
              <Grid container spacing={4}>
                {section.categories.list.map((o, idx) => {
                  return (
                    <Grid key={idx} item xs={12} sm={3} md={4}>
                      <Link key={idx} underline="none" component={RouterLink} to={o.link} sx={{ cursor: "pointer", color: "inherit" }}>
                        <Box>
                          <Box>
                            <img
                              style={{ width: "100%", height: "auto", aspectRatio: "1", objectFit: "cover", backgroundColor: "#FFF" }}
                              src={o.image}
                            />
                          </Box>

                          <Box py={2} textAlign={"center"}>
                            <Typography variant="h4" gutterBottom>{o.title}</Typography>
                            <Typography variant="body1" color="textSecondary">
                              {o.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </Box>
        )*/}
        {section.photo && (
          <Box py={15}>
            <Box px={2} id="gallery">
              <Container maxWidth="md">
                <Box textAlign="center" mb={8}>
                  <Typography variant="h2" sx={{ fontSize: "calc(100% + 2.2vw)" }} gutterBottom>
                    Custom Cakes & Treats
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontSize: "calc(75% + .75vw)" }} color="textSecondary">
                    {section.photo.subtitle || `From elegant cakes to delightful treats, we bring your vision to life for every special occasion`}
                  </Typography>
                </Box>
              </Container>
              <Box>
                {section.photo.data && (
                  <Box>
                    {!isMobile && (
                      <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={2}>
                        {section.photo.data.map((pic, idx) => {
                          return (
                            <Box key={idx} sx={{ display: isMobile && idx > 5 ? "none" : "inherit" }}>
                              <Stagger idx={idx}>
                                <img style={{ width: "100%" }} src={pic} />
                              </Stagger>
                            </Box>
                          );
                        })}
                      </Masonry>
                    )}
                    {isMobile && (
                      <Carousel
                        autoPlay
                        infiniteLoop
                        interval={3000}
                        transitionTime={1500}
                        showArrows={true}
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                      >
                        {section.photo.data.map((pic, idx) => {
                          return (
                            <Box idx={idx}>
                              <img style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }} src={pic} />
                            </Box>
                          );
                        })}
                      </Carousel>
                    )}
                  </Box>
                )}
              </Box>
              <Box mt={4} textAlign={"center"}>
                <Button component={RouterLink} variant="contained" color="primary" to={`/get-quote`} size="large" sx={{ fontSize: "20px" }}>
                  Get Quote
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {section.review && (
          <Box>
            <Box py={15} px={2} id="reviews" sx={{ backgroundColor: "#ECECEC" }}>
              <Container maxWidth="md">
                <Box mb={8} textAlign="center">
                  <Typography variant="h2" sx={{ fontSize: "calc(100% + 2.2vw)" }} gutterBottom>
                    {section.review.title || "Wall of Love"}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: "calc(75% + .75vw)" }}>
                    {section.review.subtitle || "We're grateful for your love & support"}
                  </Typography>
                </Box>
              </Container>
              <Box>
                {!isMobile && section.review.data && (
                  <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
                    {section.review.data.map((el, idx) => {
                      return (
                        <Stagger idx={idx}>
                          <Review data={el} baker={siteConfig.biz} />
                        </Stagger>
                      );
                    })}
                  </Masonry>
                )}
                {isMobile && (
                  <Carousel
                    autoPlay
                    infiniteLoop
                    interval={3000}
                    transitionTime={1500}
                    showArrows={true}
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={false}
                  >
                    {section.review.data.map((el) => {
                      return (
                        <Box key={el.id} px={2}>
                          <Review data={el} baker={siteConfig.biz} />
                        </Box>
                      );
                    })}
                  </Carousel>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <Box py={15}>
          <Container maxWidth="md">
            <Box mb={8} textAlign="center" id="flavors">
              <Typography variant="h2" sx={{ fontSize: "calc(100% + 2.2vw)" }} gutterBottom>
                {section.flavor.title || "Flavors"}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: "calc(75% + .75vw)" }}>
                {section.flavor.subtitle || "We offer an electic mix of flavor options"}
              </Typography>
            </Box>
          </Container>
          <Container maxWidth="lg">
            <Box id="flavors">{section.flavor.data && <Flavors flavors={section.flavor.data.flavors} fillings={section.flavor.data.fillings} />}</Box>
          </Container>
        </Box>
        {section.about && (
          <Box py={15} sx={{ backgroundColor: "#ECECEC" }}>
            <Container maxWidth="md">
              <Box mb={8} textAlign="center" id="about">
                <Typography variant="h2" sx={{ fontSize: "calc(100% + 2.2vw)" }} gutterBottom>
                  {section.about.title || "Our Story"}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: "calc(75% + .75vw)" }}>
                  {section.about.subtitle || ""}
                </Typography>
              </Box>
              {section.about.list.map((o, idx) => {
                return (
                  <Box key={idx} my={10}>
                    {o.mediaUrl.startsWith("<iframe") ? (
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: o.mediaUrl
                        }}
                      ></Box>
                    ) : (
                      <Grid container spacing={4} alignItems={"center"} direction={idx % 2 === 0 ? "row" : "row-reverse"}>
                        <Grid item sm={6} xs={12}>
                          {o.mediaUrl.startsWith("<iframe") ? (
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: o.mediaUrl
                              }}
                            ></Box>
                          ) : (
                            <img style={{ width: "100%", borderRadius: "4px" }} src={o.mediaUrl} />
                          )}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Preview lines="12">
                            <Typography
                              sx={{ textAlign: "justify" }}
                              dangerouslySetInnerHTML={{
                                __html: o.content
                              }}
                            />
                          </Preview>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                );
              })}
            </Container>
          </Box>
        )}
        {section.wholesale && (
          <Box py={15} sx={{ backgroundColor: "#ECECEC" }}>
            <Container maxWidth="md">
              <Box mb={8} textAlign="center" id="wholesale">
                <Typography variant="h2" sx={{ fontSize: "calc(100% + 2.2vw)" }} gutterBottom>
                  {section.wholesale.title || "Wholesale"}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: "calc(75% + .75vw)" }}>
                  {section.wholesale.subtitle || ""}
                </Typography>
              </Box>
              {section.wholesale.list.map((o, idx) => {
                return (
                  <Box key={idx} my={10}>
                    <Grid container spacing={4} alignItems={"center"} direction={idx % 2 === 0 ? "row" : "row-reverse"}>
                      <Grid item sm={6} xs={12}>
                        <img style={{ width: "100%", borderRadius: "4px" }} src={o.mediaUrl} />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Preview lines="12">
                          <Typography
                            sx={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: o.content
                            }}
                          />
                        </Preview>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Container>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}
